import React, { useState } from "react";
import "./Contact.css";
import ContactImg from './images/contact.webp';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const linkwhatsapp = () => {
    const { name, email, message } = formData;
    const whatsappMessage = `माझे नाव : ${name} , पत्ता: ${email} , समस्या : ${message}`;
    const whatsappURL = `https://api.whatsapp.com/send?phone=919209580058&text=${whatsappMessage}`;
    window.open(whatsappURL, '_blank');
  };

  return (
    <>
      <div className="contact_section">
        <div>
          <div className='subheader-details'>
            <p className="contact-head">शास्त्रीजींशी अवश्य संपर्क करा </p>
            <p>तुमच्या कोणत्याही समस्येसाठी आजच आचार्य पंडित निलेश जी शास्त्री यांना संपर्क करा</p>
          </div>
          <div className="contact_main_section">
            <div className="container">
              <div className="row-contact">
                <div className="col-lg-7 col-md-12 col-sm-12">
                  <div className="contact_box_wrapper">
                    <div className="contact_img_wrapper">
                      <img src={ContactImg} alt="" />
                    </div>
                    <div className="row mt-5">
                      <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="contact_sub_part">
                          <h4>मोबाइल नं </h4>
                          <p>9209580058</p>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="contact_sub_part">
                          <h4>ई-मेल </h4>
                          <p>info@gmil.com</p>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="contact_sub_part">
                          <h4>पत्ता </h4>
                          <p>त्र्यंबकेश्वर गुरुपीठ नाशिक </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="contact_box_wrapper">
                    <div className="contact_right_part">
                      <h3>Enquire Us</h3>
                      <p>
                      तुमच्या आयुष्यात कोणतीही समस्या असेल तर, शास्त्रीजींनी संपर्क साधा आणि तुमच्या समस्येसाठी समाधान मिळवा 
                      </p>
                    </div>
                    <div className="contact_form_section">
                      <div>
                        <label className="form_label">नाव</label>
                        <input
                          type="text"
                          name="name"
                          placeholder="Enter your Name"
                          className="form_input"
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label className="form_label">पत्ता</label>
                        <input
                          type="text"
                          name="email"  // This should be 'email' to match the formData state
                          placeholder="enter your address"
                          className="form_input"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label className="form_label">समस्या </label>
                        <textarea
                          name="message"
                          className="form_input"
                          rows={4}
                          value={formData.message}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <div className="submit_btn_wrapper">
                        <button className="button-62" onClick={linkwhatsapp}>Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
