import React from 'react';
import kundalimaking from './images/kundali making.jfif';
import kundalimatching from './images/kundali matching.webp';
import courtcase from './images/court case.webp';
import lagna from './images/lagna.jpeg';
import premprakaran from './images/relationship.jpg';
import vastushastra from './images/vastushastra.jpg';
import putraprapti from './images/santan prapti.jpg';
import promotion from './images/promotion.jpg';
import './Pooja.css'; // Import your CSS file

const Pooja = () => {
  const poojas = [
    { image: kundalimaking, description: 'कुंडली विश्लेषण' },
    { image: kundalimatching, description: 'कुंडली मॅचिंग' },
    { image: courtcase, description: 'कोर्ट केस' },
    { image: lagna, description: 'विवाह विलंब' },
    { image: premprakaran, description: 'प्रेम प्रकरण' },
    { image: vastushastra, description: 'वास्तुशास्त्र' },
    { image: putraprapti, description: 'संतान प्राप्ती'  },
    { image: promotion, description: 'प्रोमोशन'  },
  ];

  return (
    <div className="pooja-container">
      <h2 className='puja-head'>Our Services</h2>
      <p className='service-p'>पती पत्नीत वाद-विवाद, विवाहास विलंब, प्रेमात असाफलता, शिक्षण व नोकरीत अडचणी, घरात अशांती, पैसा न टिकणे, संतती प्राप्तीत अडचण, व्यावसायातील अपयश येणे, अशा अनेक समस्यावर उपाय व मार्गदर्शन करण्यात येईल</p>
      <div className="pooja-grid">
        {poojas.map((pooja, index) => (
          <div className="pooja-item" key={index}>
            <img src={pooja.image} alt={`Pooja ${index + 1}`} />
            <p className='service-des'>{pooja.description}</p>
            <a href='https://wa.link/lqy55z'><button className='book-pooja'>Book Now</button></a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pooja;
