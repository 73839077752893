import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import './Footer.css'; // Import your CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul className="footer-links">
            <li><a href="home">Home</a></li>
            <li><a href="services">Services</a></li>
            <li><a href="yantra">Yantra</a></li>
            <li><a href="stone">Stones</a></li>
            <li><a href="about">About</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Contact Us</h3>
          <p>त्र्यंबकेश्वर गुरुपीठ नाशिक  </p>
          <p>Email: info@panditnilesh.com</p>
          <p>Phone: 9209580058</p>
        </div>
        <div className="footer-section">
          <p className='follow'>Follow Us</p>
          <div className="social-icons">
            <a href="https://facebook.com"><FaFacebook /></a>
            <a href="https://twitter.com"><FaTwitter /></a>
            <a href="https://instagram.com"><FaInstagram /></a>
            <a href="https://linkedin.com"><FaLinkedin /></a>
          </div>
        </div>
      </div>
      <div className="copyright">
       <a href='https://shreedigimarketing.in/' className='copyright'> <p>&copy; 2024 Samarth Jyotish Gurupeeth | Designed By Shree Digital Marketing Agency</p></a>
      </div>
    </footer>
  );
};

export default Footer;
