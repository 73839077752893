import React, { useEffect } from 'react';
import './Home.css'; // Import your CSS file
import { Col, Container, Row } from 'react-bootstrap';
import one from './images/1-2.png'
import two from './images/2-2.png'
import three from './images/3-2.png'

const Home = () => {
  useEffect(() => {
    const overlay = document.querySelector('.overlay');
    overlay.classList.add('zoom-in');
  }, []);

  return (
 <div>
     <div className="home-container">
      <div className="overlay">
        <h1 className="main-heading"> समर्थ ज्योतिष गुरुपीठ</h1>
        <p className="sub-heading">तुमच्या आयुष्यात दडलेले सत्य जाणून घ्या</p>
        <a href='tel:9209580058'> <button className="get-started-button">Get Started</button></a>
      </div>
    </div>
    <Container fluid className='home-Why-section'>
        <h4>Why Choose Us</h4>
        <h5>तुम्हाला अपवादात्मक सेवा प्रदान करण्याच्या आमच्या क्षमतेवर आमचा ठाम विश्वास आहे.</h5>
        <Row className='why-section-row'>
          <Col sm={12} md={12} lg={4} className='why-section-coloums'>
            <div className='contents'>
              <img src={one} alt='' />
              <h6>अचूक उपचार उपाय</h6>
            </div>
          </Col>
          <Col sm={12} md={12} lg={4} className='why-section-coloums'>
            <div className='contents'>
              <img src={two} alt='' />
              <h6>गोपनीयतेची हमी</h6>
            </div>
          </Col>
          <Col sm={12} md={12} lg={4} className='why-section-coloums'>
            <div className='contents'>
              <img src={three}  alt=''/>
              <h6>2500+ आनंदी ग्राहक</h6>
            </div>
          </Col>
        </Row>
      </Container>
 </div>
  );
};

export default Home;
