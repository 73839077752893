
import React from 'react';
import './Services.css';
import pooja1Image from './images/puja1 (2).jpeg';
import pooja2Image from './images/puja2.jpeg';
import pooja3Image from './images/puja3.jpeg';
import pooja4Image from './images/puja4 (2).jpeg';
import pooja5Image from './images/IMG_20240722_150717.jpg';
import pooja6Image from './images/puja6 (2).jpeg'
import pooja7Image from './images/puja7.jpeg';
import pooja8Image from './images/puja8.jpeg';


const servicesData = [
  {
    name: 'नारायण नागबली, ',
    image: pooja1Image, // Update with actual image path
    
  },
  {
    name: 'कालसर्पयोग ',
    image:pooja2Image, // Update with actual image path
    
  },
  {
    name: 'नक्षत्र शांती',
    image: pooja3Image, // Update with actual image path
   
  },
  {
    name: 'नवग्रह शांती',
    image:pooja4Image, // Update with actual image path
    
  },
  {
    name: 'वास्तु शांती ',
    image:pooja5Image, // Update with actual image path   
  },
  {
    name: 'नारायण नागबली ',
    image:pooja7Image, // Update with actual image path
    
  },
  {
    name:  'पितृदोष ',
    image:pooja8Image, // Update with actual image path
   
  }, 
];

const Services = () => {
  return (
    <div className="services-container">
      <p className='service-head'>आमच्याकडे संपूर्ण धार्मिक पद्धतीने  पूजा केल्या जातील</p>
      <p>नारायण नागबली, कालसर्पयोग विधी, नक्षत्र शांती योगशांती, वशीकरण अशा सर्व धार्मिक पद्धतीच्या विधी पूजा पाठ शास्त्रश्रोत पद्धतीने करून दिल्या जातील.</p>
      <div className="services-grid">
        {servicesData.map((service, index) => (
          <div className="service-card" key={index}>
            <img src={service.image} alt={service.name}  className="service-image" />
           
            <div className="service-details">
              <h3 className='service-name'>{service.name}</h3>
              <p>{service.details}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;
