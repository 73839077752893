import React from 'react';
import './Stones.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import manikya from './images/manikya (2).jpeg'
import pukhraj from  './images/pushkaraj.png'
import panna from  './images/panna (2).jpeg'
import neelam from  './images/neelam.png'
import moti from  './images/moti.png'
import moonga from './images/moonga.png'
import gomed from './images/gomed.png'
import rudraksh from './images/4-Mukhi-600x713-1-removebg-preview.png'
import fmukhi from './images/5-mukhi.webp'
import sixmukhi from './images/6 mukhi.jpg'
import sevenmukhi from './images/7 mukhi.jpeg'
import emukhi from './images/8-mukhi.jpg'
import nmukhi from './images/9 mukhi.jpg'
import manianguthi from './images/manikyanguthi.jpeg'
import manipendal from './images/manikyapendal.jpeg'
import moongaanguthi from './images/moonga anguthi.jpeg'
import goldmala from './images/goldrudraksh mala.jpeg'
import silvermala from './images/rudrakshchandi.jpeg'
import rudramal from './images/rudrakshamalasimple.jpeg'
import largemala from './images/rmalalarge.jpeg'



const StoneCarousel = () => {
  const stones = [
    {
      name: 'Manikya (रुबी)',
      description: '✔ रुबी नातेसंबंध मजबूत करते ✔ व्यावसायिक उत्कृष्टता आणि सद्भावना निर्माण करते ✔ मानसिक शांती आणि भावनिक उपचार सुनिश्चित करते ✔ माणिक वाईटांपासून संरक्षण करण्यास मदत करतात ✔ रुबीमध्ये बरे करण्याचे गुणधर्म आणि आरोग्य आशीर्वाद आहेत',
      image: manikya,
      price:'11000 (5.10 carat)',
      link: 'https://topmate.io/nilesh_shastri/1101727',
    },
    {
      name: 'Pukhraj (पुष्कराज)',
      description: '✔ मानसिक स्पष्टता वाढवा ✔ झोपेची गुणवत्ता चांगली मिळवा ✔ तणावाची पातळी कमी करा ✔ अधिक ऊर्जा मिळवा ✔ रोगप्रतिकार प्रणाली कार्य वाढवा ✔ रक्ताभिसरण सुधारणे ✔ अधिक आध्यात्मिक जागरूकता मिळवा',
      image: pukhraj,
      price:'11501  (4.5 carat)',
      link: 'https://topmate.io/nilesh_shastri/1101729',
    },
    {
      name: 'Panna (पन्ना)',
      description: '✔ हे तुम्हाला हुशार आणि बौद्धिक बनवते ✔ तुमची सर्जनशीलता वाढवते ✔ आर्थिक वाढीस मदत होते ✔ संवाद सुधारतो ✔ शारीरिक स्वास्थ्य आणि शक्ती सुधारते ✔ तुम्हाला नाव आणि प्रसिद्धी मिळविण्यात मदत करते',
      image:panna,
      price:'12000  (5.5 carat)',
      link: 'https://topmate.io/nilesh_shastri/1101743',
    },
    {
      name: 'Neelam (नीलम)',
      description: '✔ निळ्या नीलम रत्नाचा प्रभाव त्वरित जाणवू शकतो. नीलम दगडाची अंगठी घातल्यानंतर केवळ एका दिवसात तुम्हाला संपत्ती, नशीब, पदोन्नती आणि संधी देऊ शकते. ✔ नीलम स्टोन अंगठी शत्रू, वाईट नजर, हेक्सिंग, मत्सर यापासून संरक्षण करण्यास मदत करते',
      image: neelam,
      price:'11051  (5.2 carat)',
      link: 'https://topmate.io/nilesh_shastri/1101744',
    },
    {
      name:'माणिक्य अंगुठी ',
      description: '✔ जर एखाद्या व्यक्तीच्या लग्नाला उशीर होत असेल किंवा लग्नात सर्व प्रकारचे अडथळे येत असतील तर तुम्ही ज्योतिषाचा सल्ला घेऊन हिरा धारण करू शकता.✔ हिरा धारण केल्याने व्यक्तीच्या आर्थिक समस्याही कमी होतात. याशिवाय ज्यांना कला, चित्रपट आणि संगीत क्षेत्रात यश मिळवायचे आहे त्यांनी हिरा घालू शकता.',
      image: manianguthi,
      price:'20001',
      link: 'https://topmate.io/nilesh_shastri/1101745',

    },
    {
      name:'Moti (मोती)',
      description: '✔ हे चंद्राचे दुष्परिणाम दूर करते, मन मजबूत करते आणि तणावग्रस्त माणसाच्या जीवनात भावनिक संतुलन आणते. ✔ हे झोपेच्या दरम्यान अस्वस्थता कमी करण्यास आणि निद्रानाश बरे करण्यास मदत करते. ✔ मोती उदासीनता, मानसिक विकार बरे करण्यास, स्मरणशक्ती सुधारण्यास मदत करते.',
      image: moti,
      price:'11501',
      link: 'https://topmate.io/nilesh_shastri/1101753',

    },
    {
      name:'Moonga (मुंगा)',
      description: 'मुंग्याचा दगड परिधान करणाऱ्याच्या वैवाहिक जीवनाला आणि जोडीदाराच्या दीर्घायुष्याला बळ देतो. ✔ रत्न धारण केल्याने परिधान करणार्‍यांचा आत्मविश्वास वाढेल आणि ते धैर्यवान बनतील. ✔ हे शारीरिक आरोग्यासाठी फायदेशीर आहे आणि अडथळे दूर करण्यास देखील मदत करते.',
      image: moonga,
      price:'11500 (5.3 carat)',
      link: 'https://topmate.io/nilesh_shastri/1101760',
    },
    {
      name:'Gomedh (गोमेद)',
      description: 'संशोधन उद्योगातील विद्यार्थी आणि व्यावसायिक दोघांसाठी हे खरोखर फायदेशीर आहे. ✔ हेसोनाइट एखाद्याच्या करिअरमध्ये प्रगती करते आणि त्यांची सामाजिक आणि आर्थिक स्थिती सुधारते. ✔ जोडपे त्यांच्या वैवाहिक जीवनात शांती आणि आनंद आणण्यासाठी प्रेम, सुसंवाद आणि शांतता वाढवण्यासाठी हेसोनाइट गोमेड स्टोन घालू शकतात.',
      image: gomed ,
      price:'12201 (4.5 carat)',
      link: 'https://topmate.io/nilesh_shastri/1101767',
    },
    {
      name:'मुंगा अंगुठी  ',
      description: 'मुंग्याचा दगड परिधान करणाऱ्याच्या वैवाहिक जीवनाला आणि जोडीदाराच्या दीर्घायुष्याला बळ देतो. ✔ रत्न धारण केल्याने परिधान करणार्‍यांचा आत्मविश्वास वाढेल आणि ते धैर्यवान बनतील. ✔ हे शारीरिक आरोग्यासाठी फायदेशीर आहे आणि अडथळे दूर करण्यास देखील मदत करते.',
      image:moongaanguthi,
      price:'15501',
      link: 'https://topmate.io/nilesh_shastri/1101772',
    },
    {
      name:'माणिक्य पेंडल ',
      description: 'संशोधन उद्योगातील विद्यार्थी आणि व्यावसायिक दोघांसाठी हे खरोखर फायदेशीर आहे. ✔ हेसोनाइट एखाद्याच्या करिअरमध्ये प्रगती करते आणि त्यांची सामाजिक आणि आर्थिक स्थिती सुधारते. ✔ जोडपे त्यांच्या वैवाहिक जीवनात शांती आणि आनंद आणण्यासाठी प्रेम, सुसंवाद आणि शांतता वाढवण्यासाठी हेसोनाइट गोमेड स्टोन घालू शकतात.',
      image: manipendal,
      price:'12201',
      link: 'https://topmate.io/nilesh_shastri/1101870',
    },

  
  ];
   const Rudraksh=[
    {
      name:'४ मुखी (रुद्राक्ष)',
      description: '✔हे माणसाला जीवनात उद्देश, कार्य आणि मोक्ष देते. त्वचारोग,✔मानसिक क्षमता, एकाग्रता आणि सर्जनशीलता यामध्ये विशेष फायदे आहेत.',
      image: rudraksh,
      price:'6001',
      link: 'https://topmate.io/nilesh_shastri/1101872',
    },
    {
      name:'५ मुखी (रुद्राक्ष)',
      description: 'पाच मुखी खुद्द रुद्ररूप आहे आणि तो सर्व कामना पूर्ण करणारा आहे. याच्यामुळे पाप वृद्धी नाहीशी होऊन मनुष्य भक्तिमार्गाकडे वळतो. या रुद्राक्षामुळे किडनी, शुगर, लठ्ठपणा असे आजार बरे होतात.',
      image: fmukhi,
      price:'6501',
      link: 'https://topmate.io/nilesh_shastri/1101927',
    },
    {
      name:'६ मुखी (रुद्राक्ष)',
      description: 'सहा मुखी रुद्राक्ष कार्तिकेय स्वरूप आहे तो उजव्या हातात धारण केला असता विशेष फल देतो. काहींच्या मते हा गणेश स्वरूपही आहे व तो उजव्या हाता बांधला असता धन, विद्या व आरोग्य देतो.',
      image: sixmukhi,
      price:'5201',
      link: 'https://topmate.io/nilesh_shastri/1101935',
    },
    {
      name:'७ मुखी (रुद्राक्ष)',
      description: 'सात मुखी रुद्राक्षाला अनंग म्हणतात आणि तो धारण करणार्‍याला कधीही दारिद्र्य येत नाही. याचे पूजनाने सहा मातृक, सात अश्व व सात ऋषी प्रसन्न होतात. शारीरिक दुर्बलता, उदर रोग, पक्षाघात, काळजी, हाडाचे विकार, कर्करोग, दमा, कमजोरी इतर आजार असल्यास सात मुखी रुद्राक्ष धारण केलं जातं.',
      image: sevenmukhi,
      price:'6101',
      link: 'https://topmate.io/nilesh_shastri/1101958',
    },
    {
      name:'८ मुखी (रुद्राक्ष)',
      description: 'आठ मुखी रुद्राक्ष वसुमूर्ती भैरवरूप आहे. हा धारण करणार्‍याला भरपूर आयुष्य लाभते. अशांती, सर्पभय, त्वचा रोग, गुप्त रोग इतर आजारावर हे रुद्राक्ष धारण करण्याचा सल्ला दिला जातो.',
      image: emukhi,
      price:'7301',
      link: 'https://topmate.io/nilesh_shastri/1101966',
    },
    {
      name:'९ मुखी (रुद्राक्ष)',
      description: 'नऊ मुखी रुद्राक्ष कपिल मुनी आहे आणि त्याची अधिष्ठाची देवता दुर्गा माहेश्वरी आहे, हा रुद्राक्ष भक्तिपूर्वक डाव्या हातात धारण केला असता विशेष फल देतो. काही लोक याला स्वरूपही मानतात. फुफ्फुसासाठी, ताप, नेत्र रोग,कान रोग, संततीसाठी, ओटीपोटात वेदना, संक्रामक रोग शांतीसाठी धारण केलं जातं.',
      image: nmukhi,
      price:'5301',
      link: 'https://topmate.io/nilesh_shastri/1101995',
    },
    {
      name:'गोल्ड रुद्राक्ष माळ ',
      description: 'नऊ मुखी रुद्राक्ष कपिल मुनी आहे आणि त्याची अधिष्ठाची देवता दुर्गा माहेश्वरी आहे, हा रुद्राक्ष भक्तिपूर्वक डाव्या हातात धारण केला असता विशेष फल देतो. काही लोक याला स्वरूपही मानतात. फुफ्फुसासाठी, ताप, नेत्र रोग,कान रोग, संततीसाठी, ओटीपोटात वेदना, संक्रामक रोग शांतीसाठी धारण केलं जातं.',
      image: goldmala,
      price:'10501',
      link: 'https://topmate.io/nilesh_shastri/1101997',
    },
    {
      name:'रुद्राक्ष माळ',
      description: 'नऊ मुखी रुद्राक्ष कपिल मुनी आहे आणि त्याची अधिष्ठाची देवता दुर्गा माहेश्वरी आहे, हा रुद्राक्ष भक्तिपूर्वक डाव्या हातात धारण केला असता विशेष फल देतो. काही लोक याला स्वरूपही मानतात. फुफ्फुसासाठी, ताप, नेत्र रोग,कान रोग, संततीसाठी, ओटीपोटात वेदना, संक्रामक रोग शांतीसाठी धारण केलं जातं.',
      image: rudramal,
      price:'6201',
      link: 'https://topmate.io/nilesh_shastri/1102007',
    },
    {
      name:'सिल्वर रुद्राक्ष माळ',
      description: 'नऊ मुखी रुद्राक्ष कपिल मुनी आहे आणि त्याची अधिष्ठाची देवता दुर्गा माहेश्वरी आहे, हा रुद्राक्ष भक्तिपूर्वक डाव्या हातात धारण केला असता विशेष फल देतो. काही लोक याला स्वरूपही मानतात. फुफ्फुसासाठी, ताप, नेत्र रोग,कान रोग, संततीसाठी, ओटीपोटात वेदना, संक्रामक रोग शांतीसाठी धारण केलं जातं.',
      image: silvermala,
      price:'11000',
      link: 'hhttps://topmate.io/nilesh_shastri/1102091',
    },
    {
      name:'रुद्राक्ष माळ(मोठी )',
      description: 'नऊ मुखी रुद्राक्ष कपिल मुनी आहे आणि त्याची अधिष्ठाची देवता दुर्गा माहेश्वरी आहे, हा रुद्राक्ष भक्तिपूर्वक डाव्या हातात धारण केला असता विशेष फल देतो. काही लोक याला स्वरूपही मानतात. फुफ्फुसासाठी, ताप, नेत्र रोग,कान रोग, संततीसाठी, ओटीपोटात वेदना, संक्रामक रोग शांतीसाठी धारण केलं जातं.',
      image: largemala,
      price:'11000',
      link: 'https://topmate.io/nilesh_shastri/1102093',
    },

    
    
  ];
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div className="stone-carousel">
      <p className='ratna-head'>संपूर्ण सिद्ध रत्ने</p>
      <p className='ratna-p'>पुजा विधान आणि मंत्रासह प्रमाणित आणि मूळ राशी रत्न (रुबी, मोती, पन्ना, कोरल, पुष्कराज, नीलम, डायमंड, गोमेद, लसूण) कोरल, एमराल्ड, क्रिस्टल विधी आणि मंत्रांसह उपलब्ध .</p>
      <Carousel responsive={responsive}>
        {stones.map((stone, index) => (
          <div className="stone-container" key={index}>
            <div className="stone-card">
              <div className="stone-imgBx">
                <img src={stone.image} alt={stone.name} className="stone-image" />
              </div>
              <div className="stone-contentBx">
                <h2 className="stone-name">{stone.name}</h2>
                <p className='stone-price'>&#8377;{stone.price}</p>
                <p className="stone-description">{stone.description}</p>
                <a href={stone.link} target="_blank" rel="noopener noreferrer" className="stone-buy-now">Buy Now</a>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
      <p className='rudraksh-head'>रुद्राक्ष ४ मुखी ते ९ मुखी व रुद्राक्ष माळा उपलब्ध </p>
      <Carousel responsive={responsive}>
        {Rudraksh.map((rudra, index) => (
          <div className="stone-container" key={index}>
            <div className="stone-card">
              <div className="stone-imgBx">
                <img src={rudra.image} alt={rudra.name} className="rudraksh-image" />
              </div>
              <div className="stone-contentBx">
                <h2 className="rudra-name">{rudra.name}</h2>
                <p className='rudra-price'>&#8377;{rudra.price}</p>
                <p className="stone-description">{rudra.description}</p>
                <a href={rudra.link} target="_blank" rel="noopener noreferrer" className="stone-buy-now">Buy Now</a>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default StoneCarousel;
