import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import './Navbar.css';
import logo from './images/compressed_gm_40_img_615816_31b89292_1705998614127_sc.jpg'

const Navbar = () => {
  return (
    <div className="navbar-t">
      <div className="navbar-top">
   
        <a href="https://wa.link/yr0w9k" className="navbar-contact">📞9209580058</a>
        <a href="mailto:atriastrocenter@gmail.com" className="navbar-contact">✉️ atriastrocenter@gmail.com</a>
        
      
      </div>
      <div className="navbar-mainp">
        <div className="navbar-logo">
          <img src={logo} alt="Logo" className="logo-img" />
          <div className="navbar-title">
            <div className='pandit-name'> समर्थ ज्योतिष गुरुपीठ  </div>
            <div className="subtitle"> आचार्य पंडित निलेशजी शास्त्री  </div>
          </div>
        </div>
        <div className="navbar-links">
          <ScrollLink to="home" smooth={true} duration={500} className="navbar-link">Home</ScrollLink>
          <ScrollLink to="about" smooth={true} duration={500} className="navbar-link">About</ScrollLink>
          <ScrollLink to="services" smooth={true} duration={500} className="navbar-link">Services</ScrollLink>
          <ScrollLink to="yantra" smooth={true} duration={500} className="navbar-link">Yantra</ScrollLink>
          <ScrollLink to="stone" smooth={true} duration={500} className="navbar-link">Stones</ScrollLink>
          <ScrollLink to="contact" smooth={true} duration={500} className="navbar-link">Enquiry</ScrollLink>
        </div>
      </div>
    </div>
  );
};

export default Navbar;