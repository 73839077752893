
import About from './About';
import './App.css';
import Stones from './Stones'
import Contact from './Contact';
import Home from './Home';
import Navbar from './Navbar';
import "bootstrap/dist/css/bootstrap.min.css";
import Yantra from './Yantra';
import Footer from './Footer';
import Pooja from './Pooja';
import Services from './Services';

function App() {
  return (
    <div >
     <section>
      <Navbar/>
     </section>
     <section id='home'>
      <Home/>
     </section>
     <section id='about'>
      <About/>
     </section>
     <section id='stone'>
      <Stones/>
     </section>
     <section id='services'>
        <Services/>
      </section>
     <section id='pooja'>
      <Pooja/> 
     </section>
     <section id='yantra'>
       <Yantra/>
     </section>
     <section id='contact'>
      <Contact/>
     </section>
     <section>
      <Footer/>
     </section> 

    </div>
  );
}

export default App;
