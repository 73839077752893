import React from "react";
import "./About.css";
import about1 from './images/guru.png'
import aboutimg from './images/about_img2.png'
import { MdCheck } from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";

const About = () => {
  const items1 = [
    { list: "जीवनाच्या सर्व टप्प्यांसाठी फक्त ५ मिनिटांत सर्वोत्तम अंदाज मिळवा." },
    { list: "फक्त एका कॉलवर 1 आठवड्याचे तपशीलवार अंदाज मिळवा." },
    { list: "एका बैठकीत अनेक समस्यांसाठी सर्वोत्तम उपाय मिळवा." },
    { list: "तुमचे जीवन सुरळीत आणि शांत करण्यासाठी आम्ही येथे आहोत." },
    { list: "आम्‍ही तुमच्‍या जीवनात सकारात्मकता आणि आनंद आणणारी कुंडली देऊ." },
    { list: "विवाह विलंब | विवाह जमण्यास अडचण | पति पत्नी मतभेद " },
    { list: "नोकरीत आणि व्यापरउद्योगात अपयश | शिक्षणात अडचण  " },
    { list: "प्रेमात असफलता | कोर्ट कचेरी | गुप्त शत्रूपीड़ा | घरात अशांती " },
    { list: "मंगळ दोष | चांडाळ दोष | अंगारक योग | शनी दोष | ग्रहणयोग" },
    { list: "कालसर्प दोष | पितृ दोष | नारायण नागबली दोष" },
  ];

  return (
    <div className="about_section">
      <div className='subheader-details'>
        <p className="about-head">About Us</p>
        <p className="about-p">तुमच्या कोणत्याही समस्येसाठी आजच आचार्य पंडित निलेशजी शास्त्री यांना संपर्क करा</p>
      </div>
      <div className="about_main_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="about_img_wrapper">
                <img src={about1} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="about_right_p">
              <p>
                  आचार्य पंडित निलेशजी शास्त्री सारखे तज्ञ ज्योतिषी ज्योतिषशास्त्राच्या विविध उप-भागांची माहिती आकर्षित करून
                  विविध समस्यांसाठी उत्तरे देऊ शकतात उदाहरणार्थ हस्तरेखा, अंकशास्त्र, वैदिक ज्योतिष आणि वास्तुशास्त्र.
                </p>
                <p>
                  ज्योतिष आचार्य पंडित निलेशजी शास्त्री हे नाशिकमधील अत्यंत प्रतिष्ठित ज्योतिषी आहेत, ते त्यांच्या कौशल्य आणि समुपदेशन
                  कौशल्यासाठी प्रसिद्ध आहेत. पाच वर्षांच्या अनुभवाने, त्यांनी सर्व वयोगटातील आणि पार्श्वभूमीच्या व्यक्तींसाठी एक
                  विश्वासू सल्लागार म्हणून नाव कमावले आहे. पंडित निलेशजी शास्त्री यांच्या जवळच्या स्वभावामुळे लोकांना, विशेषत: तरुणांना
                  त्यांच्यावर विश्वास ठेवणे आणि त्यांच्या समस्यांसाठी मार्गदर्शन घेणे सोपे होते.
                </p>
                <p>
                  ज्योतिषशास्त्रावर आधारित अचूक भविष्यवाण्यांचा त्यांचा सातत्यपूर्ण ट्रॅक रेकॉर्ड म्हणजे पंडित निलेशजी शास्त्री यांना वेगळे
                  ठरवते. त्याच्या अंतर्ज्ञानी वाचनाने असंख्य व्यक्तींना माहितीपूर्ण निर्णय घेण्यास आणि जीवनातील आव्हानांमध्ये
                  नेव्हिगेट करण्यासाठी मार्गदर्शन केले आहे. अंकशास्त्रातील गुंतागुंत उलगडणे असो किंवा ज्योतिषशास्त्राच्या सामर्थ्याचा
                  उपयोग करणे असो, पंडित निलेशजी शास्त्री प्रभावी उपाय ऑफर करण्यात उत्कृष्ट आहेत.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="info-div">असा मनुष्य नाही कि ,ज्याला समस्या नाही आणि अशी समस्या नाही जिला समाधान नाही</div>
      <div className="container-secnod">
        <div className="about_eg_section">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="about_eg_left pe-lg-5">
                <ul className="about_ul">
                  {items1.map((item) => (
                    <li key={item.list}>
                      <MdCheck size={30} className="about_check_icon" />
                      <p>{item.list}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="about_eg_img">
                <img src={aboutimg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
