import React from 'react';
import './Yantra.css';
import sarvakashtanivarak from './images/sarvakashatnivaran.jpeg'
import sarvakaryasiddhi from './images/sarvakaryasiddhi.jpeg'
import mahayantra from './images/sarvaraksha mahayantra.jpeg'
import sarvaraksha from './images/sarvaraksha.jpeg'
import rognivaran from './images/rognashakyantra.jpeg'
import navgrah from './images/navgrah.jpeg'
import shrisampurn from './images/shisampurnmahayantra.jpeg'
import vyavarvruddhi from './images/vyaparvruddhi.jpg'


const Yantra = () => {
  const yantras = [
    {
      name: 'श्री सम्पूर्ण सर्वकष्ट निवारण यंत्र',
      image:sarvakashtanivarak,
      description:"संपूर्ण सर्व कष्ट निवारण यंत्र हे एक अत्यंत प्रभावी आणि शक्तिशाली यंत्र आहे, जे जीवनातील विविध अडचणींना आणि कष्टांना दूर करण्यासाठी वापरले जाते. हे यंत्र विशेषत: त्यांच्यासाठी उपयुक्त आहे ज्यांना आर्थिक, शारीरिक, मानसिक किंवा आध्यात्मिक अडचणींनी त्रास होत आहे.",
      price:4200,
      link: 'https://topmate.io/nilesh_shastri/1102094',
    },
    {
      name: 'सर्व कार्य सिद्धी यंत्र',
      image: sarvakaryasiddhi,
      description:"सर्व कार्य सिद्धी यंत्र हे एक अत्यंत प्रभावी आणि शक्तिशाली यंत्र आहे, जे जीवनातील विविध कार्ये यशस्वीरीत्या पूर्ण करण्यासाठी वापरले जाते. हे यंत्र विशेषत: त्यांच्यासाठी उपयुक्त आहे ज्यांना त्यांच्या कामांमध्ये सातत्याने अडचणी आणि अडथळे येतात.",
      price:3200,
      link:'https://topmate.io/nilesh_shastri/1102106',
    },
    {
      name: 'सर्व बाधा मुक्ति यंत्र',
      image: mahayantra ,
      description:"सर्व बाधा मुक्ति यंत्र हे एक अत्यंत प्रभावी आणि शक्तिशाली यंत्र आहे, जे जीवनातील विविध बाधा आणि अडथळे दूर करण्यासाठी वापरले जाते. हे यंत्र विशेषत: त्यांच्यासाठी उपयुक्त आहे ज्यांना जीवनातील प्रत्येक क्षेत्रात अडचणी आणि त्रास होत असतो.",
      price:2500,
      link:'https://topmate.io/nilesh_shastri/1102114'
    },
    {
        name: 'सर्व रक्षा महा यंत्र ',
        image: sarvaraksha,
        description:"सर्व रक्षा महा यंत्र हे एक अत्यंत प्रभावी आणि शक्तिशाली यंत्र आहे, जे जीवनातील विविध संकटांपासून आणि धोक्यांपासून संरक्षण करण्यासाठी वापरले जाते.",
        price:3500,
        link:'https://topmate.io/nilesh_shastri/1102115'
      },
      {
        name: 'सम्पूर्ण रोगनाशक यंत्र',
        image: rognivaran,
        description:"संपूर्ण रोगनाशक यंत्र हे एक अत्यंत प्रभावी आणि शक्तिशाली यंत्र आहे, जे शारीरिक आणि मानसिक आरोग्य सुधारण्यासाठी आणि सर्व प्रकारच्या रोगांपासून मुक्ती मिळवण्यासाठी वापरले जाते. ",
        price:4100,
        link:'https://topmate.io/nilesh_shastri/1102116'
      },
      {
        name: 'संपूर्ण नवग्रह यंत्र',
        image: navgrah,
        description:"संपूर्ण नवग्रह यंत्र हे एक अत्यंत प्रभावी आणि शक्तिशाली यंत्र आहे, जे नवग्रहांच्या अशुभ प्रभावांपासून मुक्ती मिळवून शुभफल प्राप्त करण्यासाठी वापरले जाते.",
        price:3500,
        link:'https://topmate.io/nilesh_shastri/1102117'
      },
      {
        name: 'श्री सम्पूर्ण यंत्र',
        image:shrisampurn,
        description:"श्री संपूर्ण यंत्र हे एक अत्यंत प्रभावी आणि शक्तिशाली यंत्र आहे, जे जीवनातील सर्व प्रकारच्या अडचणी दूर करण्यासाठी, सुख-समृद्धी, यश, आणि शांतता प्राप्त करण्यासाठी वापरले जाते. ",
        price:2500,
        link:'https://topmate.io/nilesh_shastri/1102121'
      },
      {
        name: 'श्री व्यापार वृद्धि यंत्र',
        image:vyavarvruddhi,
        description:"श्री व्यापार वृद्धि यंत्र हे एक अत्यंत प्रभावी आणि शक्तिशाली यंत्र आहे, जे व्यवसायात यश, प्रगती आणि समृद्धि प्राप्त करण्यासाठी वापरले जाते. ",
        price:2500,
        link:'https://topmate.io/nilesh_shastri/1102122 '
      },
  ];

  return (
    <div className="yantra-container">
      <h1 className="yantra-heading">यंत्र</h1>
      <p className='yantra-p'>आमच्याकडे सिद्ध जप अनुष्ठान झालेले यंत्र मिळतील</p>
      <div className="yantra-grid">
        {yantras.map((yantra, index) => (
          <div key={index} className="yantra-card">
            <img src={yantra.image} alt={yantra.name} className="yantra-image" />
            <h2 className="yantra-title">{yantra.name}</h2>
            <p>{yantra.description}</p>
            <p className='yantra-price'>&#8377;{yantra.price}</p>
           <a href={yantra.link}> <buttion target="_blank" rel="noopener noreferrer" className='book-button'>Book Now</buttion></a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Yantra;
